<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-form :inline="true" size="small" :model="searchData" @submit.native.prevent>
        <el-form-item v-if="storeEntityList.length>1" label="门店">
          <el-select v-model="searchData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间筛选">
          <el-date-picker v-model="searchData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="font_weight_600 martp_5">实收统计</div>
    <el-table size="small" border show-summary :summary-method="getPayOverviewSummaries" class="martp_5" :data="entityMonthlyStatementPayOverview" v-loading="entityMonthlyStatementPayOverviewLoading">
      <el-table-column prop="Name" width="120px"  label="收款方式"></el-table-column>
      <el-table-column align="right" prop="SalePayAmount" label="销售金额">
        <template slot-scope="scope">
          {{scope.row.SalePayAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column align="right" prop="ArrearPayAmount" label="补欠款金额">
        <template slot-scope="scope">
          {{scope.row.ArrearPayAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="RefunAmount" align="right" label="退款金额">
        <template slot-scope="scope">
          <span v-if="scope.row.RefunAmount>0" class="color_red"> -{{scope.row.RefunAmount | NumFormat}}</span>
          <span v-else>{{scope.row.RefunAmount | NumFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column align="right" label="实收金额">
        <template slot-scope="scope">
          <span v-if="(scope.row.SalePayAmount+scope.row.ArrearPayAmount-scope.row.RefunAmount)<0" class="color_red">{{(scope.row.SalePayAmount+scope.row.ArrearPayAmount-scope.row.RefunAmount).toFixed(2)|NumFormat}}</span>
          <span v-else class="color_green">{{(scope.row.SalePayAmount+scope.row.ArrearPayAmount-scope.row.RefunAmount).toFixed(2)|NumFormat}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="font_weight_600 martp_10">销售统计</div>
    <el-table size="small" show-summary :summary-method="getSaleOverviewSummaries" class="martp_5" :data="entityMonthlyStatementSaleOverview" v-loading="entityMonthlyStatementSaleOverviewLoading">
      <el-table-column width="120px" prop="GoodsType" label="商品类型"></el-table-column>
      <el-table-column align="center" label="销售信息">
        <el-table-column align="right" prop="SalePayAmount" label="实收金额">
          <template slot-scope="scope">
            {{scope.row.SalePayAmount | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column align="right" prop="SaleSavingCardDeductionAmount" label="卡抵扣金额">
          <template slot-scope="scope">
            {{scope.row.SaleSavingCardDeductionAmount | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column align="right" prop="SaleLargessSavingCardDeductionAmount" label="赠送卡抵扣金额">
          <template slot-scope="scope">
            {{scope.row.SaleLargessSavingCardDeductionAmount | NumFormat}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="补欠款信息">
        <el-table-column align="right" label="实收金额">
          <template slot-scope="scope">
            {{scope.row.ArrearPayAmount | NumFormat}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="退款信息">
        <el-table-column align="right" prop="RefunPayAmount" label="退款金额">
          <template slot-scope="scope">
            <span v-if="scope.row.RefunPayAmount>0" class="color_red">-{{scope.row.RefunPayAmount | NumFormat}}</span>
            <span v-else>{{scope.row.RefunPayAmount | NumFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="RefundSavingCardDeductionAmount" label="退回卡金额">
          <template slot-scope="scope">
            <span v-if="scope.row.RefundSavingCardDeductionAmount>0" class="color_red">-{{scope.row.RefundSavingCardDeductionAmount | NumFormat}}</span>
            <span v-else>{{scope.row.RefundSavingCardDeductionAmount | NumFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="RefundLargessSavingCardDeductionAmount" label="退回赠送卡金额">
          <template slot-scope="scope">
            <span v-if="scope.row.RefundLargessSavingCardDeductionAmount>0" class="color_red">-{{scope.row.RefundLargessSavingCardDeductionAmount | NumFormat}}</span>
            <span v-else>{{scope.row.RefundLargessSavingCardDeductionAmount | NumFormat}}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="合计">
        <el-table-column align="right" label="实收合计">
          <template slot-scope="scope">
            <span v-if="(scope.row.SalePayAmount+scope.row.ArrearPayAmount-scope.row.RefunPayAmount).toFixed(2)<0" class="color_red">{{(scope.row.SalePayAmount+scope.row.ArrearPayAmount-scope.row.RefunPayAmount).toFixed(2) |NumFormat}}</span>
            <span v-else>{{(scope.row.SalePayAmount+scope.row.ArrearPayAmount-scope.row.RefunPayAmount).toFixed(2) |NumFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="卡抵扣合计">
          <template slot-scope="scope">
            <span v-if="(scope.row.SaleSavingCardDeductionAmount-scope.row.RefundSavingCardDeductionAmount).toFixed(2)<0" class="color_red">{{(scope.row.SaleSavingCardDeductionAmount-scope.row.RefundSavingCardDeductionAmount).toFixed(2) |NumFormat}}</span>
            <span v-else>{{(scope.row.SaleSavingCardDeductionAmount-scope.row.RefundSavingCardDeductionAmount).toFixed(2) |NumFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="赠送卡抵扣合计">
          <template slot-scope="scope">
            <span v-if="(scope.row.SaleLargessSavingCardDeductionAmount-scope.row.RefundLargessSavingCardDeductionAmount).toFixed(2)<0" class="color_red">{{(scope.row.SaleLargessSavingCardDeductionAmount-scope.row.RefundLargessSavingCardDeductionAmount).toFixed(2) |NumFormat}}</span>
            <span v-else>{{(scope.row.SaleLargessSavingCardDeductionAmount-scope.row.RefundLargessSavingCardDeductionAmount).toFixed(2) |NumFormat}}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="font_weight_600 martp_10">消耗统计</div>
    <el-table size="small" show-summary :summary-method="getTreatOverviewSummaries" class="martp_5" :data="entityMonthlyStatementTreatOverview" v-loading="entityMonthlyStatementTreatOverviewLoading">
      <el-table-column prop="GoodsType" width="120px" label="消耗方式"></el-table-column>
      <el-table-column align="center" label="消耗信息">
        <el-table-column align="right" prop="TreatPayAmount" label="消耗本金">
          <template slot-scope="scope">
            {{scope.row.TreatPayAmount | NumFormat}}
          </template>
        </el-table-column>
        <el-table-column align="right" prop="TreatLargessAmount" label="消耗赠金">
          <template slot-scope="scope">
            {{scope.row.TreatLargessAmount | NumFormat}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="退消耗信息">
        <el-table-column align="right" prop="RefundTreatPayAmount" label="退消耗本金">
          <template slot-scope="scope">
            <span v-if="scope.row.RefundTreatPayAmount>0" class="color_red">-{{scope.row.RefundTreatPayAmount | NumFormat}}</span>
            <span v-else>{{scope.row.RefundTreatPayAmount | NumFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" prop="RefundTreatLargessAmount" label="退消耗赠金">
          <template slot-scope="scope">
            <span v-if="scope.row.RefundTreatLargessAmount>0" class="color_red">-{{scope.row.RefundTreatLargessAmount | NumFormat}}</span>
            <span v-else>{{scope.row.RefundTreatLargessAmount | NumFormat}}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="合计">
        <el-table-column align="right" label="本金合计">
          <template slot-scope="scope">
            <span v-if="(scope.row.TreatPayAmount-scope.row.RefundTreatPayAmount).toFixed(2)<0" class="color_red">{{(scope.row.TreatPayAmount-scope.row.RefundTreatPayAmount).toFixed(2) |NumFormat}}</span>
            <span v-else>{{(scope.row.TreatPayAmount-scope.row.RefundTreatPayAmount).toFixed(2) |NumFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="赠金合计">
          <template slot-scope="scope">
            <span v-if="(scope.row.TreatLargessAmount-scope.row.RefundTreatLargessAmount).toFixed(2)<0" class="color_red">{{(scope.row.TreatLargessAmount-scope.row.RefundTreatLargessAmount).toFixed(2) |NumFormat}}</span>
            <span v-else>{{(scope.row.TreatLargessAmount-scope.row.RefundTreatLargessAmount).toFixed(2) |NumFormat}}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Entity/monthlyStatement";

var Enumerable = require("linq");

export default {
  name: "ReportEntityMonthlyStatement",
  data() {
    return {
      searchData: {
        EntityID: null,
        QueryDate: [new Date(), new Date()],
      },
      loading: false,
      entityMonthlyStatementPayOverviewLoading: false,
      entityMonthlyStatementPayOverview: [], //门店实收统计
      storeEntityList: [], //门店列表
      entityMonthlyStatementSaleOverviewLoading: false,
      entityMonthlyStatementSaleOverview: [], //门店销售统计
      entityMonthlyStatementTreatOverviewLoading: false,
      entityMonthlyStatementTreatOverview: [], //门店消耗统计
    };
  },
  methods: {
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    //实收统计列表
    getEntityMonthlyStatementPayOverview(params) {
      var that = this;
      that.entityMonthlyStatementPayOverviewLoading = true;
      API.getEntityMonthlyStatementPayOverview(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityMonthlyStatementPayOverview = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.entityMonthlyStatementPayOverviewLoading = false;
        });
    },

    //实收统计合计
    getPayOverviewSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = Enumerable.from(data).sum((i) => {
        return i.SalePayAmount;
      });
      sums[2] = Enumerable.from(data).sum((i) => {
        return i.ArrearPayAmount;
      });
      sums[3] = Enumerable.from(data).sum((i) => {
        return i.RefunAmount;
      });

      var filter_NumFormat = this.$options.filters["NumFormat"];

      if (
        (
          parseFloat(sums[1]) +
          parseFloat(sums[2]) -
          parseFloat(sums[3])
        ).toFixed(2) < 0
      ) {
        sums[4] = (
          <span class="font_weight_600 color_red">
            {filter_NumFormat(
              (
                parseFloat(sums[1]) +
                parseFloat(sums[2]) -
                parseFloat(sums[3])
              ).toFixed(2)
            )}
          </span>
        );
      } else {
        sums[4] = (
          <span class="font_weight_600 color_green">
            {filter_NumFormat(
              (
                parseFloat(sums[1]) +
                parseFloat(sums[2]) -
                parseFloat(sums[3])
              ).toFixed(2)
            )}
          </span>
        );
      }
      sums[1] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[1]).toFixed(2))}
        </span>
      );
      sums[2] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[2]).toFixed(2))}
        </span>
      );
      if (parseFloat(sums[3]).toFixed(2) > 0) {
        sums[3] = (
          <span class="font_weight_600 color_red">
            -{filter_NumFormat(parseFloat(sums[3]).toFixed(2))}
          </span>
        );
      } else {
        sums[3] = (
          <span class="font_weight_600 ">
            {filter_NumFormat(parseFloat(sums[3]).toFixed(2))}
          </span>
        );
      }

      return sums;
    },

    //销售统计列表
    getEntityMonthlyStatementSaleOverview(params) {
      var that = this;
      that.entityMonthlyStatementSaleOverviewLoading = true;
      API.getEntityMonthlyStatementSaleOverview(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityMonthlyStatementSaleOverview = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.entityMonthlyStatementSaleOverviewLoading = false;
        });
    },

    //销售统计合计
    getSaleOverviewSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = Enumerable.from(data).sum((i) => {
        return i.SalePayAmount;
      });
      sums[2] = Enumerable.from(data).sum((i) => {
        return i.SaleSavingCardDeductionAmount;
      });
      sums[3] = Enumerable.from(data).sum((i) => {
        return i.SaleLargessSavingCardDeductionAmount;
      });
      sums[4] = Enumerable.from(data).sum((i) => {
        return i.ArrearPayAmount;
      });
      sums[5] = Enumerable.from(data).sum((i) => {
        return i.RefunPayAmount;
      });
      sums[6] = Enumerable.from(data).sum((i) => {
        return i.RefundSavingCardDeductionAmount;
      });
      sums[7] = Enumerable.from(data).sum((i) => {
        return i.RefundLargessSavingCardDeductionAmount;
      });

      var filter_NumFormat = this.$options.filters["NumFormat"];

      if (
        (
          parseFloat(sums[1]) +
          parseFloat(sums[4]) -
          parseFloat(sums[5])
        ).toFixed(2) < 0
      ) {
        sums[8] = (
          <span class="font_weight_600 color_red">
            {filter_NumFormat(
              (
                parseFloat(sums[1]) +
                parseFloat(sums[4]) -
                parseFloat(sums[5])
              ).toFixed(2)
            )}
          </span>
        );
      } else {
        sums[8] = (
          <span class="font_weight_600">
            {filter_NumFormat(
              (
                parseFloat(sums[1]) +
                parseFloat(sums[4]) -
                parseFloat(sums[5])
              ).toFixed(2)
            )}
          </span>
        );
      }

      if ((parseFloat(sums[2]) - parseFloat(sums[6])).toFixed(2) < 0) {
        sums[9] = (
          <span class="font_weight_600 color_red">
            {filter_NumFormat(
              (parseFloat(sums[2]) - parseFloat(sums[6])).toFixed(2)
            )}
          </span>
        );
      } else {
        sums[9] = (
          <span class="font_weight_600">
            {filter_NumFormat(
              (parseFloat(sums[2]) - parseFloat(sums[6])).toFixed(2)
            )}
          </span>
        );
      }

      if ((parseFloat(sums[3]) - parseFloat(sums[7])).toFixed(2) < 0) {
        sums[10] = (
          <span class="font_weight_600 color_red">
            {filter_NumFormat(
              (parseFloat(sums[3]) - parseFloat(sums[7])).toFixed(2)
            )}
          </span>
        );
      } else {
        sums[10] = (
          <span class="font_weight_600">
            {filter_NumFormat(
              (parseFloat(sums[3]) - parseFloat(sums[7])).toFixed(2)
            )}
          </span>
        );
      }

      sums[1] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[1]).toFixed(2))}
        </span>
      );
      sums[2] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[2]).toFixed(2))}
        </span>
      );
      sums[3] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[3]).toFixed(2))}
        </span>
      );
      sums[4] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[4]).toFixed(2))}
        </span>
      );

      if (parseFloat(sums[5]).toFixed(2) > 0) {
        sums[5] = (
          <span class="font_weight_600 color_red">
            -{filter_NumFormat(parseFloat(sums[5]).toFixed(2))}
          </span>
        );
      } else {
        sums[5] = (
          <span class="font_weight_600">
            {filter_NumFormat(parseFloat(sums[5]).toFixed(2))}
          </span>
        );
      }
      if (parseFloat(sums[6]).toFixed(2) > 0) {
        sums[6] = (
          <span class="font_weight_600 color_red">
            -{filter_NumFormat(parseFloat(sums[6]).toFixed(2))}
          </span>
        );
      } else {
        sums[6] = (
          <span class="font_weight_600">
            {filter_NumFormat(parseFloat(sums[6]).toFixed(2))}
          </span>
        );
      }
      if (parseFloat(sums[7]).toFixed(2) > 0) {
        sums[7] = (
          <span class="font_weight_600 color_red">
            -{filter_NumFormat(parseFloat(sums[7]).toFixed(2))}
          </span>
        );
      } else {
        sums[7] = (
          <span class="font_weight_600">
            {filter_NumFormat(parseFloat(sums[7]).toFixed(2))}
          </span>
        );
      }

      return sums;
    },

    //消耗统计列表
    getEntityMonthlyStatementTreatOverview(params) {
      var that = this;
      that.entityMonthlyStatementTreatOverviewLoading = true;
      API.getEntityMonthlyStatementTreatOverview(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityMonthlyStatementTreatOverview = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.entityMonthlyStatementTreatOverviewLoading = false;
        });
    },

    //消耗统计合计
    getTreatOverviewSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = Enumerable.from(data).sum((i) => {
        return i.TreatPayAmount;
      });
      sums[2] = Enumerable.from(data).sum((i) => {
        return i.TreatLargessAmount;
      });
      sums[3] = Enumerable.from(data).sum((i) => {
        return i.RefundTreatPayAmount;
      });
      sums[4] = Enumerable.from(data).sum((i) => {
        return i.RefundTreatLargessAmount;
      });

      var filter_NumFormat = this.$options.filters["NumFormat"];

      if ((parseFloat(sums[1]) - parseFloat(sums[3])).toFixed(2) < 0) {
        sums[5] = (
          <span class="font_weight_600 color_red">
            {filter_NumFormat(
              (parseFloat(sums[1]) - parseFloat(sums[3])).toFixed(2)
            )}
          </span>
        );
      } else {
        sums[5] = (
          <span class="font_weight_600">
            {filter_NumFormat(
              (parseFloat(sums[1]) - parseFloat(sums[3])).toFixed(2)
            )}
          </span>
        );
      }

      if ((parseFloat(sums[2]) - parseFloat(sums[4])).toFixed(2) < 0) {
        sums[6] = (
          <span class="font_weight_600 color_red">
            {filter_NumFormat(
              (parseFloat(sums[2]) - parseFloat(sums[4])).toFixed(2)
            )}
          </span>
        );
      } else {
        sums[6] = (
          <span class="font_weight_600">
            {filter_NumFormat(
              (parseFloat(sums[2]) - parseFloat(sums[4])).toFixed(2)
            )}
          </span>
        );
      }

      sums[1] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[1]).toFixed(2))}
        </span>
      );
      sums[2] = (
        <span class="font_weight_600">
          {filter_NumFormat(parseFloat(sums[2]).toFixed(2))}
        </span>
      );

      if (parseFloat(sums[3]).toFixed(2) > 0) {
        sums[3] = (
          <span class="font_weight_600 color_red">
            -{filter_NumFormat(parseFloat(sums[3]).toFixed(2))}
          </span>
        );
      } else {
        sums[3] = (
          <span class="font_weight_600">
            {filter_NumFormat(parseFloat(sums[3]).toFixed(2))}
          </span>
        );
      }
      if (parseFloat(sums[4]).toFixed(2) > 0) {
        sums[4] = (
          <span class="font_weight_600 color_red">
            -{filter_NumFormat(parseFloat(sums[4]).toFixed(2))}
          </span>
        );
      } else {
        sums[4] = (
          <span class="font_weight_600">
            {filter_NumFormat(parseFloat(sums[4]).toFixed(2))}
          </span>
        );
      }
      return sums;
    },

    // 搜索
    handleSearch() {
      var that = this;
      if (that.searchData.QueryDate != null) {
        var params = {
          EntityID: that.searchData.EntityID,
          StartDate: that.searchData.QueryDate[0],
          // StartDate: "2021-03-02",
          EndDate: that.searchData.QueryDate[1],
        };
        that.getEntityMonthlyStatementPayOverview(params);
        that.getEntityMonthlyStatementSaleOverview(params);
        that.getEntityMonthlyStatementTreatOverview(params);
      }
    },
  },
  mounted() {
    var that = this;
    that.getstoreEntityList();
    that.handleSearch();
  },
};
</script>
<style lang="scss">
</style>