/**
 * 对账单api
 */
import * as API from '@/api/index'

export default {
    // 营业报表-实收统计
    getEntityMonthlyStatementPayOverview: params => {
        return API.POST('api/entityMonthlyStatement/payOverview', params)
    },
    // 营业报表-销售统计
    getEntityMonthlyStatementSaleOverview: params => {
        return API.POST('api/entityMonthlyStatement/saleOverview', params)
    },
    // 营业报表-消耗统计
    getEntityMonthlyStatementTreatOverview: params => {
        return API.POST('api/entityMonthlyStatement/treatOverview', params)
    },
}